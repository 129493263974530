import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";

export const FavoritesComponent = () => {
  const { apiOrigin, audience } = getConfig();
  const { getAccessTokenSilently, user } = useAuth0();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
    firstName: "",
    lastName: "",
    flavor: ""
  });

  const url = {
    limon: "https://estaticos-cdn.prensaiberica.es/clip/09fe558b-6dc6-4a51-93d5-5c758cf403a4_16-9-aspect-ratio_default_0.jpg",
    fresa: "https://www.redaccionmedica.com/images/destacados/las-fresas-mejoran-los-sintomas-de-la-enfermedad-inflamatoria-intestinal-8046.jpg",
    chocolate: "https://i1.wp.com/www.adelantosdigital.com/web/wp-content/uploads/2017/01/chocolate-negro.jpg",
    vainilla: "https://www.lifeder.com/wp-content/uploads/2016/12/propiedades-de-la-vainilla.jpg",
    all: "https://p4.wallpaperbetter.com/wallpaper/195/152/8/balls-colored-ice-cream-horn-wallpaper-preview.jpg"
  }


  //getAccessTokenWithPopup se usa con MFA y getAccessTokenSilently sin MFA

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: audience,
        scope: "update:user",
      });
      console.log("Access Token: " + token);

      const response = await fetch(
        `${apiOrigin}/api/users/billing/google-oauth2|101183399191027049685`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    switch (event.target.id) {
      case "firstName":
        setState({ firstName: event.target.value });
        break;
      case "lastName":
        setState({ lastName: event.target.value });
        break;
      case "flavor":
        setState({ lastName: event.target.value });
        break;
    }
  };

  const divStyle = {
    width: '500px',
    align: 'center'
  };

  return (
    <>
      <div className="mb-5" style={divStyle}>
        <h1>Completa tu informacion personal</h1>
        <Form className="form">
          <FormGroup>
            <Label for="firstName">Nombre</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              onChange={handleChange}
            />
            <Label for="lastName">Apellido</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              onChange={handleChange}
            />
            <Label for="favoriteFlavor">Sabor favorito de helado</Label>
            <div onChange={handleChange} id="flavors">
              <div> <input type="radio" name="sabor" value="limon" id="flavor" />Limon<img src={url.limon} width="70px" /></div>
              <div><input type="radio" name="sabor" value="fresa" id="flavor" />Fresa<img src={url.fresa} width="70px" /></div>
              <div><input type="radio" name="sabor" value="chocolate" id="flavor" />Chocolate<img src={url.chocolate} width="70px" /></div>
              <div><input type="radio" name="sabor" value="vainilla" id="flavor" />Vainilla<img src={url.vainilla} width="70px" /></div>
              <div><input type="radio" name="sabor" value="all" id="flavor" />Todas las anteriores<img src={url.all} width="70px" /></div>
            </div>

          </FormGroup>
          <Button
            color="primary"
            className="mt-5"
            onClick={callApi}
            disabled={!audience}
          >
            Guardar
          </Button>
        </Form>
      </div>
    </>
  );
};

export default withAuthenticationRequired(FavoritesComponent, {
  onRedirecting: () => <Loading />,
});
