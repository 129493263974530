const contentData = [
  //Limon
  {
    title: "El mejor sabor",
    img: "https://i.ytimg.com/vi/SjXhrY1gqxY/maxresdefault.jpg",
    flavor: "limon",
    description:
      "Seleccionamos los mejores limones para que disfrutes de un sabor refrescante y autentico"
  },
  {
    title: "Sabor adicional",
    img: "https://d1uz88p17r663j.cloudfront.net/resized/7243d21e8d745b4c981cf90624fcf716_NIEVE_DE_PEPINO_CON_LIMON_1200_600.jpg",
    flavor: "limon",
    description:
      "Disfrutalo como gustes con chamoy, con chile en polvo o si eres realmente valiente con extra limón",
  },
  {
    title: "La mejor calidad",
    img: "https://1.bp.blogspot.com/-Ocb7Mt1KxG4/X91R5NigNsI/AAAAAAAABFk/ygxAI6NdBHIz5dTO9fN4JXKaVVUIMcxeACLcBGAsYHQ/s1280/los-perros-pueden-comer-nieve-de-limon.jpg",
    flavor: "limon",
    description:
      "Refrescante sin duda alguna",
  },
  {
    title: "La base la eliges tú",
    img: "https://gourmetdemexico.com.mx/wp-content/uploads/2018/04/nieve-de-limo%CC%81n-.jpg",
    flavor: "limon",
    description:
      "Tú eliges como la quieres: en cono, en vaso o en cazuela.",
  },
  {
    title: "Y ahora que digo",
    img: "https://www.guiadelnino.com/var/guiadelnino.com/storage/images/recetas-para-ninos-y-bebes/helados-y-sorbetes/polos-de-limon/7625258-3-esl-ES/polos-de-limon.jpg",
    flavor: "limon",
    description:
      "Viva México tres veces",
  },
  //Fresa
  {
    title: "Sabor fresa",
    img: "https://estaticos.miarevista.es/uploads/images/recipe/5ef212215cafe8762041baa6/helado-fresa.jpg",
    flavor: "fresa",
    description:
      "Con paciencia y pocos ingredientes podemos disfrutar de un delicioso helado casero sabor fresa",
  },
  {
    title: "Sabor fresa",
    img: "https://elrecetarioonline.com/wp-content/uploads/2020/07/HELADO-FRESAS.jpg",
    flavor: "fresa",
    description:
      "El éxito de la receta reside en la precisión de los ingredientes, el punto de congelación de la fresa y que la nata esté bien fría",
  },
  {
    title: "Sabor fresa",
    img: "http://www.2000agro.com.mx/wp-content/uploads/helado-fresa.jpg",
    flavor: "fresa",
    description:
      "Un bocado delicioso y cremoso que enloquece nuestro paladar",
  },
  {
    title: "Sabor fresa",
    img: "https://diariodecuba.com/sites/default/files/images/2022-02/cuba-literatura-narrativa.jpg",
    flavor: "fresa",
    description:
      "En cucurucho, con cuchara y barquillos o acompañando un delicioso postre, sea como sea como helado es un disfrute.",
  },
  {
    title: "Sabor fresa",
    img: "https://i.blogs.es/989036/ok-1/840_560.jpg",
    flavor: "fresa",
    description:
      "Un bocado delicioso y cremoso que enloquece nuestro paladar",
  },
  {
    title: "Sabor fresa",
    img: "https://cdn2.cocinadelirante.com/sites/default/files/styles/gallerie/public/images/2021/03/helado-de-fresa-sin-leche-y-sin-azucar.jpg",
    flavor: "fresa",
    description:
      "El éxito de la receta reside en la precisión de los ingredientes, el punto de congelación de la fresa y que la nata esté bien fría",
  },
  //chocolate
  {
    title: "Sabor chocolate",
    img: "https://recetastips.com/wp-content/uploads/2020/05/helado-de-chocolate-6-1.jpg",
    flavor: "chocolate",
    description:
      "Las primeras recetas de helado de chocolate fueron publicados en Nápoles, Italia en 1693 en The Modern Steward de Antonio Latini. El chocolate fue uno de los primeros sabores de helado, creado antes que el helado de vainilla, ya que las bebidas comunes como el chocolate caliente, el café y el té fueron los primeros alimentos que se convirtieron en postres helados.",
  },
  {
    title: "Sabor chocolate",
    img: "https://www.recetasderechupete.com/wp-content/uploads/2019/07/shutterstock_1010248351.jpg",
    flavor: "chocolate",
    description:
      "El helado de chocolate generalmente se elabora mezclando cacao en polvo junto con los huevos, la crema, la vainilla y el azúcar que se usan para hacer el helado de vainilla. ",
  },
  {
    title: "Sabor chocolate",
    img: "https://uploads.vibra.co/1/2020/11/helado-de-chocolate-casero-destacada.jpg",
    flavor: "chocolate",
    description:
      "A veces, el licor de chocolate se usa además del cacao en polvo, o se usa exclusivamente, para crear el sabor del chocolate.7​El cacao en polvo le da al helado de chocolate su color marrón, y es poco común agregarle otros colorantes",
  },
  {
    title: "Sabor chocolate",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0ubucGpNPrSovvu-uxRqeREdtpDAbdYLvpR5IqWWr7VQm0T8vuiXorQ0J5w5WicgYUrQ&usqp=CAU",
    flavor: "chocolate",
    description:
      "El Codex Alimentarius, que proporciona un conjunto internacional de normas para los alimentos, establece que el sabor del helado de chocolate debe provenir de sólidos de cacao sin grasa que deben comprender al menos el 2.0% del peso de la mezcla",
  },
  {
    title: "Sabor chocolate",
    img: "https://img-global.cpcdn.com/recipes/b94ffd5e72bdaa3f/680x482cq70/helado-de-chocolate-foto-principal.jpg",
    flavor: "chocolate",
    description:
      "El contenido mínimo de grasa del helado de chocolate suele ser del 8%, independientemente de la cantidad de edulcorante de chocolate en la receta",
  },
  {
    title: "Sabor chocolate",
    img: "https://recetacubana.com/wp-content/uploads/2021/08/helado-de-chocolate-casero.jpg",
    flavor: "chocolate",
    description:
      "En 1775, el médico italiano Filippo Baldinucci escribió un tratamiento titulado De sorbetti, en el que recomendaba el helado de chocolate como remedio para diversas afecciones médicas, incluida la gota y el escorbuto",
  },
  //vainilla
  {
    title: "Sabor vainilla",
    img: "https://www.recetas-veganas.com/base/stock/Recipe/112-image/112-image_web.jpg",
    flavor: "vainilla",
    description:
      "El Helado de Vainilla Mexicana de Dulce Origen®, tiene una textura cremosa el cual contiene trocitos de almendra tostada",
  },
  {
    title: "Sabor vainilla",
    img: "https://www.jappi.com.co/wp-content/uploads/2021/01/como-preparar-helado-vegano-imagen-destacada-thegem-blog-timeline-large.jpg",
    flavor: "vainilla",
    description:
      "Hecho con ingredientes mexicanos de la más alta calidad. ",
  },
  {
    title: "Sabor vainilla",
    img: "https://www.gourmet.cl/wp-content/uploads/2016/09/Helado_Vainilla-web.jpg",
    flavor: "vainilla",
    description:
      "Contiene leche, almendra y ajonjolí. Puede contener avellana, cacahuate, gluten, nuez y soya. ",
  },
  {
    title: "Sabor vainilla",
    img: "https://cdn6.dibujos.net/images/recetas/big/helado-de-vainilla.jpg",
    flavor: "vainilla",
    description:
      "Helado sabor vainilla [agua, azúcares añadidos (azúcar y glucosa), leche entera en polvo, grasa vegetal sin hidrogenar (5%), estabilizantes (goma de algarrobo, goma guar y carragenina), emulsificantes (mono, di y triglicéridos de ácidos grasos), saborizante, sal yodada y colorante natural (beta caroteno)] y almendras (6%).",
  },
  {
    title: "Sabor vainilla",
    img: "https://static-sevilla.abc.es/media/gurmesevilla/2016/07/helado-de-vainilla.jpg",
    flavor: "vainilla",
    description:
      "El Helado de Vainilla Mexicana de Dulce Origen®, tiene una textura cremosa el cual contiene trocitos de almendra tostada. Hecho con ingredientes mexicanos de la más alta calidad. ",
  },
  {
    title: "Sabor vainilla",
    img: "https://postressaludables.com/wp-content/uploads/2014/07/heladovanillapostressaludables.png",
    flavor: "vainilla",
    description:
      "Helado de Vainilla Mexicana",
  },
  
];

export default contentData;
