import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import { getConfig } from "../config";
import axios from 'axios';
import { QRCodeCanvas } from "qrcode.react";
import qs from "qs";
import util from "util"
import Movies from "../components/Movies";

export const LoginQR = () => {

  const { domain, audienceTenant, deviceClientId, scope } = getConfig();
  const { isAuthenticated } = useAuth0();
  //const main = { }
  const [state, setState] = useState({
    showCodigo: false,
    error: null,
    device_code: null,
    user_code: null,
    verification_uri: null,
    verification_uri_complete: null,
    user: null,
    showUser: false,
    token: null,
    given_name: null,
    picture: null
  });

  useEffect(() => {
    console.log(localStorage.getItem('state'));
    const btnLogin = document.getElementById('btnLogin');
    btnLogin.style.display = "none";
    if (localStorage.getItem('state') === null) {
      getCodeDevice();
    } else {
      getUserInfo(localStorage.getItem('state'));
    }
  }, []);

  const index = async () => {
    console.log(window.location.href);
    window.location.href = '/';
  }

  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={state.verification_uri_complete}
      size={200}
      bgColor={"#fff"}
      level={"H"}
    />
  );

  const getCodeDevice = async () => {
    //Consumir API Update User  
    await axios.post(`https://${domain}/oauth/device/code`,
      qs.stringify({
        client_id: deviceClientId,
        scope: 'openid profile read:profile',
        audience: audienceTenant
      })
    ).then(async (res) => {
      console.log("Datos: " + util.inspect(res.data));
      setState({
        ...state,
        device_code: res.data.device_code,
        user_code: res.data.user_code,
        verification_uri: res.data.verification_uri,
        verification_uri_complete: res.data.verification_uri_complete,
        showCodigo: true
      });
      return res.data.device_code;
    }).catch((error) => {
      setState({
        ...state,
        error: error
      });
      alert("Se presento un error");
      index();
      console.log("Hubo un error: " + error);
    });
  }




  const verificarCodigo = async () => {
    await axios.post(`https://${domain}/oauth/token`,
      qs.stringify({
        client_id: deviceClientId,
        device_code: state.device_code,
        grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
        scope: 'openid profile read:profile'
      })
    ).then(async (res) => {
      const token = res.data.access_token;
      console.log("Datos: " + token);
      setState({
        ...state,
        showCodigo: false,
        showUser: true,
        token: token
      });
      localStorage.setItem("state", token);
      getUserInfo(token);

    }).catch((error) => {
      console.log("Hubo un error: " + error);
    });

  };

  const getUserInfo = async (token) => {
    console.log('Userinfo: ' + token);
    await axios.get(`https://${domain}/userinfo`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      console.log("Datos: " + util.inspect(res.data));
      setState({
        ...state,
        given_name: res.data.given_name,
        picture: res.data.picture,
        showCodigo: false,
        showUser: true
      })
    }).catch((error) => {
      console.log("Hubo un error: " + error);
    });

  };

  const closeSession = () => {
    localStorage.removeItem("state");
    window.location.reload();
  }


  // setState({ ...state, firstName: event.target.value });

  const divStyle = {
    width: '500px',
    align: 'center'
  };

  return (
    <>
      <div className="mb-5" style={divStyle}>
        {state.showCodigo && (
          <section>
            <div className="result-block-container">
              <div className="result-block" data-testid="api-result">
                <h3>Escanea el código QR con tu dispositivo móvil</h3>
                <br />
                <div className="qrcode__container">
                  <div>{qrcode}</div>
                  <div>
                    <h4>Si no puede escanear el código vaya a la siguiente URL:</h4>
                    <span>{state.verification_uri}</span>
                    <br /><br />
                    <h5>Ingrese el siguiente código:</h5>
                    <span>{state.user_code}</span>

                  </div>
                  <div>
                    <Button
                      color="primary"
                      className="mt-5"
                      onClick={verificarCodigo}
                    >
                      Continuar
                    </Button>
                  </div>
                </div>
              </div>

            </div>
          </section>
        )}
        {state.showUser && (
          <section>
            <div>
              <div style={{ float: "left", margin: "10px" }}>
                <img
                  src={state.picture}
                  alt="Profile"
                  width={60}
                  className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                />
              </div>
              <div style={{ float: "left", margin: "10px" }}>
                <h3>Hola, {state.given_name} </h3>
              </div>
            </div>
            <div>
              <Movies />
            </div>
            <div>
              <Button
                color="primary"
                className="mt-5"
                onClick={closeSession}
              >
                Cerrar sesión
              </Button>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default LoginQR;
