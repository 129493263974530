import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import contentData from "../utils/contentData";

export const Movies = () => {
  const { user } = useAuth0();
  const favorite_flavor = (typeof user === 'undefined') ? 'all' : user["http://icecream/favorite_flavor"];
  const duplicate_email = (typeof user === 'undefined') ? 'all' : user["http://icecream/duplicate_email"];


  //const data = contentData.map();
  let body;
  for (let index = 0; index < contentData.length; index++) {
    const element = contentData[index];
    if (element.flavor === favorite_flavor || favorite_flavor === 'all' || favorite_flavor === 'undefined') {
      body = <>{body}<Col md={5} className="mb-6">
        <h6 className="mb-3">
          <FontAwesomeIcon icon="link" className="mr-2" />{element.title}
        </h6>
        <a href="https://developer.mozilla.org/">
          <img src={element.img} width="350" />
        </a >
        <p>{element.description} </p>
      </Col>
      </>
        ;
    }

  }


  return (
    <div>
      <table>
        <tr>
          <td><img src="https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-400,h-600,bg-CCCCCC:w-400.0,h-660.0,cm-pad_resize,bg-000000,fo-top:oi-discovery-catalog@@icons@@like_202006280402.png,ox-24,oy-617,ow-29:q-80/et00329656-fwbxxphedq-portrait.jpg" width="200" /></td>
          <td><img src="https://i.ytimg.com/vi/wp3eV6uPz44/movieposter_en.jpg" width="200" /></td>
          <td><img src="https://i.ytimg.com/vi/0KBTJBiL3oQ/movieposter_en.jpg" width="200" /></td>
          <td><img src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/men-in-black-international-1560277677.jpg?crop=1xw:1xh;center,top&resize=480:*" width="200" /></td>
          <td><img src="https://cdn.hyprop.co.za/movies/images/5365/5365-1-12-3-1657527017.jpg?w=493.5&webp" width="200" /></td>
          <td><img src="https://wwwimage-us.pplusstatic.com/thumbnails/photos/w370-q80/movie_asset/28/53/20/sotr_salone_poster_1400x2100.jpg" width="200" /></td>
          <td><img src="https://akamaividz2.zee5.com/image/upload/w_504,h_756,c_scale,f_webp,q_auto:eco/resources/0-0-1z5202609/portrait/1920x7704418ddb372c74d429e43a715c1e6a7e0.jpg" width="200" /></td>
        </tr>
        <tr>
          <td><img src="https://static.toiimg.com/photo/msid-66052420/66052420.jpg?14938" width="200" /></td>
          <td><img src="https://www.comingsoon.net/assets/uploads/2021/12/FFzlXHlXoAIdHvW.jpeg" width="200" /></td>
          <td><img src="https://1.bp.blogspot.com/-12tErvRA_1I/XSQdCU4NL9I/AAAAAAABB3o/OrxAnStF6mUA1DK0idYd9hOKPtmX65_HACLcBGAs/s1600/dhaakad.jpg" width="200" /></td>
          <td><img src="http://timesofindia.indiatimes.com/photo/70124698.cms" width="200" /></td>
          <td><img src="https://cdn.hyprop.co.za/movies/images/5365/5365-1-12-3-1657527017.jpg?w=493.5&webp" width="200" /></td>
          <td><img src="https://images.moviesanywhere.com/c9b039ffb143f8eea8b7d66d3cfd8949/8a030796-a13c-4acd-b88f-dbd2b161c0b1.jpg?h=375&resize=fit&w=250" width="200" /></td>
          <td><img src="https://cdn.moviefone.com/admin-uploads/posters/prey-movie-poster_1654648712.jpg?d=360x540&q=80" width="200" /></td>
        </tr>      
      </table>
    </div>
  );
}

export default Movies;
