import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import util from 'util'


export const ExternalApiComponent = () => {
  const { apiOrigin, audience } = getConfig();
  const history = useHistory();

  const [state, setState] = useState({
    showResult: false,
    credit_card: '',
    expire_date: '',
    error: null,
    month: '',
    year: '',
    fullname: '',
    showUpdate: false,
    auxCreditCard: ''
  });

  const buttonStyle = {
    marginLeft: '20px'
  };

  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0(); //getAccessTokenWithPopup se usa con MFA y getAccessTokenSilently sin MFA

  const callApi = async () => {
    try {
      const token = await getAccessTokenWithPopup({
        audience: audience,
        scope: "read:billing",
      });

      await axios.get(`${apiOrigin}/users/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(async (res) => {
        const { credit_card, expire_date, fullname } = await res.data.user;
        setState({
          ...state,
          showResult: true,
          credit_card: credit_card,
          expire_date: expire_date,
          fullname: fullname
        });
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const populateForm = async () => {
    try {
      let exp = state.expire_date;
      exp = exp.split('/');

      const card = state.credit_card;
      console.log(card);
      setState({
        ...state,
        showResult: false,
        showUpdate: true,
        month: exp.length > 0 ? exp[0] : '01',
        year: exp.length > 0 ? exp[1] : '22',
        credit_card: card,
        auxCreditCard: card
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const updateCard = async () => {
    try {
      const token = await getAccessTokenSilently();
      await axios.put(`${apiOrigin}/card`,
        {
          _id: user.sub,
          cardNumber: state.auxCreditCard,
          expire: state.month + "/" + state.year
        }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then(async (res) => {
        setState({
          ...state,
          showResult: true,
          credit_card: state.auxCreditCard,
          expire_date: state.month + "/" + state.year,
          auxCreditCard: '',
          showUpdate: false
        });
      });

    } catch (error) {
      console.log(error);
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  const handleChange = (event) => {
    console.log(event.target.id);
    switch (event.target.id) {
      case "cardNumber":
        setState({ ...state, auxCreditCard: event.target.value });
        break;
      case "month":
        setState({ ...state, month: event.target.value });
        break;
      case "year":
        setState({ ...state, year: event.target.value });
        break;
    }
  };

  return (
    <>

      <div className="mb-5">
        <h1>Formas de pago</h1>
        <div><img src="https://www.radioonlinehd.com/assets/images/usapagos-530x397.png" width="250px" /><br />Las Formas de Pago son los distintos medios a través de los cuales se pueden pagar los productos adquiridos.
          Esta transacción incluye tanto las formas de pago convencionales, como el efectivo, como las formas de pago electrónicas, entre ellas las tarjetas de crédito.
          Sus datos están protegidos con un factor de autenticidad como lo es un token o una notificación push</div>
        <Button
          color="primary"
          className="mt-5"
          onClick={callApi}
          disabled={state.showResult || state.showUpdate}
        >
          Mostrar datos
        </Button>
        {state.showResult && (
          <Button
            color="warning"
            style={buttonStyle}
            className="mt-5"
            onClick={populateForm}
            disabled={!audience}
          >
            Actualizar datos
          </Button>
        )}
      </div>



      {state.showResult && (
        <section>
          <div className="result-block-container">
            <div className="result-block" data-testid="api-result">
              <div className="card-group">
                <div className="card">
                  <div className="logo-card">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/556px-Visa_Inc._logo.svg.png"></img>
                  </div>
                  <div className="chip">
                    <img src="https://lirp.cdn-website.com/876572b9/dms3rep/multi/opt/Dekart+favicon-1920w.png"></img>
                  </div>
                  <div className="number">{state.credit_card}</div>
                  <div className="name">Nombre:{state.fullname}</div>
                  <div className="to">Vigencia: {state.expire_date}</div>
                  <div className="ring"></div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "-30px" }}>

          </div>
        </section>
      )}

      {state.showUpdate && (
        <section>
          <div className="result-block-container">
            <div className="result-block" data-testid="api-result">
              <h1>Ingresa los datos de tu tarjeta</h1>
              <Form className="form">
                <FormGroup>
                  <Label for="firstName">Numero de Tarjeta</Label>
                  <Input
                    type="text"
                    name="cardNumber"
                    id="cardNumber"
                    value={state.auxCreditCard}
                    onChange={handleChange}
                    style={{ width: "300px" }}
                  />
                  <br />
                  <p>Fecha de expiracion</p>
                  <div>
                    <label>
                      Mes:&nbsp;
                      <select value={state.month} onChange={handleChange} id="month">
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </label>

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <label>
                      Año:&nbsp;
                      <select value={state.year} onChange={handleChange} id="year">
                        <option value="22">2022</option>
                        <option value="23">2023</option>
                        <option value="24">2024</option>
                        <option value="25">2025</option>
                        <option value="26">2026</option>
                        <option value="27">2027</option>
                        <option value="28">2028</option>
                        <option value="29">2029</option>
                        <option value="30">2030</option>
                        <option value="31">2031</option>
                        <option value="32">2032</option>
                        <option value="33">2033</option>
                      </select>
                    </label>
                  </div>
                </FormGroup>
                <Button
                  color="primary"
                  className="mt-5"
                  onClick={updateCard}
                  disabled={!audience}
                >
                  Guardar
                </Button>
                <Button
                  color="warning"
                  className="mt-5"
                  style={{ marginLeft: '0.8rem' }}
                  onClick={() => { setState({ ...state, showUpdate: false, showResult: true }) }}
                  disabled={!audience}
                >
                  Cancelar
                </Button>
              </Form>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default withAuthenticationRequired(ExternalApiComponent, {
  onRedirecting: () => <Loading />,
});